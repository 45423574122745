<template>
  <section class="pay-methods field">
    <header class="field__title">Способы оплаты</header>
    <div class="pay-methods__buttons">
      <button
        v-for="pm in payMethods"
        v-bind:key="pm.id"
        class="pay-methods__button"
        v-bind:class="'pay-methods__button_' + pm.id"
      >
        <img v-bind:src="require(`@/assets/img/${pm.id}.svg`)" alt />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      payMethods: [{ id: "apple-pay" }, { id: "google-pay" }, { id: "sbp" }],
    };
  },
};
</script>

<style lang="scss" scoped>
.pay-methods {
  &__buttons {
    display: flex;
    flex-flow: column nowrap;
  }

  &__button {
    height: 78px;
    margin-top: 21px;
    border-radius: 18px;
    border: none;

    &_apple-pay {
      background-color: #212125;
    }

    &_google-pay {
      background-color: #fff;
    }

    &_sbp {
      background-color: #1d1545;
    }
  }
}
</style>
