<template>
  <section class="menu-item">
    <div v-if="product.image_url" class="menu-img">
      <img :src="product.image_url" alt="" />
    </div>
    <div v-else class="menu-img placeholder" style="display: none">
      <img :src="photoPlaceholder" alt="Placeholder" />
    </div>
    <header class="menu-header">{{ product.name }}</header>
    <div class="menu-weight" :class="{ 'menu-weight_hidden': !product.weight }">
      {{ product.weight }} г
    </div>
    <div class="menu-description">{{ product.description }}</div>
    <MenuButton
      :price="product.price"
      :quantity="qty"
      :onAdd="add"
      :onRemove="remove"
    />
  </section>
</template>

<script>
import MenuButton from "@/components/MenuButton.vue";
import editableMenuItem from "@/mixins/editableMenuItem";

export default {
  mixins: [editableMenuItem],
  props: ["product"],
  data: () => ({
    photoPlaceholder: require("@/assets/img/photo_placeholder.svg"),
  }),
  components: {
    MenuButton,
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/components/menu.scss";
</style>
