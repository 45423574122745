import { mapGetters, mapMutations, mapState } from "vuex";
import {
  CHANGE_TOTAL_ERROR,
  MENU_ADD,
  MENU_REMOVE,
} from "@/store/mutations.type";

export default {
  computed: {
    ...mapGetters(["caseName", "total"]),
    ...mapState({
      menuQty(state) {
        return state[this.caseName].qty;
      },
    }),
    qty() {
      return this.menuQty[this.product.name];
    },
  },
  methods: {
    ...mapMutations({
      menuAdd: MENU_ADD,
      menuRemove: MENU_REMOVE,
      changeTotalError: CHANGE_TOTAL_ERROR,
    }),
    add() {
      this.menuAdd(this.product);
      this.changeTotalError(!this.total);
    },
    remove() {
      this.menuRemove(this.product);
    },
  },
};
