<template>
  <section class="pay-methods_with-button field">
    <header class="field__title">Способы оплаты</header>
    <div class="pay-methods_with-button__buttons">
      <button
        v-for="pm in payMethods"
        v-bind:key="pm.id"
        class="pay-methods_with-button__button"
        :class="{
          ['pay-methods_with-button__button_' + pm.id]: true,
          'pay-methods_with-button__button_selected': pm.id === selected,
        }"
        @click="select(pm.id)"
      >
        <img v-bind:src="require(`@/assets/img/${pm.id}_black.svg`)" alt />
      </button>
    </div>
    <button
      class="pay-methods_with-button__pay-button"
      :class="{ ['pay-methods_with-button__pay-button_disabled']: !isComplete }"
      @click="onValidate"
    >
      Оплатить
    </button>
  </section>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import { VALIDATE } from "../store/mutations.type";

export default {
  computed: {
    ...mapGetters(["isComplete"]),
  },
  data: function () {
    return {
      payMethods: [{ id: "apple-pay" }, { id: "google-pay" }, { id: "sbp" }],
      selected: undefined,
      sbpLink: null,
    };
  },
  methods: {
    select: function (value) {
      this.selected = value;
      if (value === "sbp" && this.sbpLink !== null) {
        window["slideUpWidget"].openWidget(this.sbpLink);
      }
    },
    onValidate() {
      if (!this.isComplete) {
        return false;
      }
      this.validate();
    },
    ...mapMutations({ validate: VALIDATE }),
  },
  mounted: function () {
    this.selected = this.payMethods[0].id;
    let url = "/test-sbp/test/sbp/link";
    axios
      .get(url)
      .then(({ data: link }) => {
        this.sbpLink = link;
      })
      .catch((err) => {
        console.error(`Error loading SBP link from ${url}\n${err.message}`);
      });
  },
};
</script>

<style lang="scss">
.pay-methods_with-button {
  &__buttons {
    display: flex;
    flex-flow: row nowrap;
  }

  &__button {
    flex: 1 0 0;
    height: 78px;
    margin-top: 16px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: #fff;
    border-radius: 18px;
    border: 2px solid #fff;

    > img {
      margin: auto;
    }

    &_selected {
      border: 2px solid #212125;
    }

    &:not(:last-child) {
      margin-right: 18px;
    }
  }

  &__pay-button {
    height: 78px;
    width: 100%;
    font-size: 21px;
    margin-top: 21px;
    background-color: #212125;
    color: #f0f2f3;
    border-radius: 18px;
    border: none;

    &_disabled {
      opacity: 0.21;
    }
  }
}
</style>
