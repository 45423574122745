<template>
  <v-app>
    <div
      class="loader-container"
      :class="{
        ['loader-container_loading']: isLoading,
        ['loader-container_fetching']: isFetching,
      }"
    >
      <div class="loader">Пожалуйста, подождите...</div>
    </div>
    <router-view />
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["isLoading", "isFetching"]),
  },
};
</script>

<style lang="scss">
@import "~@/assets/fonts/Inter";
@import "~@/assets/fonts/JetBrainsMono";
@import "~@/assets/scss/variables";
@import "~@/assets/scss";
@import "~@/assets/scss/loader";
</style>
