<template>
  <div class="custom-amount-form">
    <Header />
    <section class="main">
      <CustomAmount />
      <ReceiptSend />
      <PayMethods />
      <Footer />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CustomAmount from "@/components/CustomAmount.vue";
import ReceiptSend from "@/components/ReceiptSend.vue";
import PayMethods from "@/components/PayMethods.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "CustomAmountForm",
  components: {
    Header,
    CustomAmount,
    ReceiptSend,
    PayMethods,
    Footer,
  },
};
</script>
