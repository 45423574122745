import { mapGetters, mapMutations, mapState } from "vuex";
import {
  CHANGE_TOTAL_ERROR,
  ITEMS_ADD,
  ITEMS_REMOVE,
} from "@/store/mutations.type";

export default {
  computed: {
    ...mapGetters(["caseName", "total"]),
    ...mapState({
      menuQty(state) {
        return state[this.caseName].qty;
      },
    }),
    qty() {
      return this.menuQty[this.product.name];
    },
  },
  methods: {
    ...mapMutations({
      itemsAdd: ITEMS_ADD,
      itemsRemove: ITEMS_REMOVE,
      changeTotalError: CHANGE_TOTAL_ERROR,
    }),
    add() {
      let product = this.product;
      let index = this.productSizeIndex;
      this.itemsAdd({ product, index });
      this.changeTotalError(!this.total);
    },
    remove() {
      let product = this.product;
      let index = this.productSizeIndex;
      this.itemsRemove({ product, index });
    },
    processWidth(tmp) {
      const { btn } = this.$refs;
      const startWidth = btn.scrollWidth;
      const endWidth = tmp.scrollWidth;
      let width = startWidth;
      btn.style.width = `${width}px`;
      let start = performance.now();
      const diff = endWidth - width;
      const isExpanding = diff > 0;
      const self = this;
      const duration = 200;
      requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) {
          timeFraction = 1;
        }
        width = startWidth + timeFraction * diff;
        if (
          (isExpanding && width >= endWidth) ||
          (!isExpanding && width <= endWidth) ||
          !self.processingWidth
        ) {
          btn.style.width = null;
          self.processingWidth = false;
          return;
        }
        btn.style.width = `${width}px`;
        requestAnimationFrame(animate);
      });
    },
  },
};
