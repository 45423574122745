<template>
  <div class="custom-amount-form">
    <Header />
    <section class="main">
      <CustomAmount />
      <ReceiptSend />
      <PayMethods />
      <Footer />
    </section>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { SET_FETCHING } from "../store/mutations.type";
import Header from "@/components/Header.vue";
import CustomAmount from "@/components/CustomAmount.vue";
import ReceiptSend from "@/components/ReceiptSend.vue";
import PayMethods from "@/components/PayMethodsWithButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    CustomAmount,
    ReceiptSend,
    PayMethods,
    Footer,
  },
  methods: {
    ...mapMutations({
      setFetching: SET_FETCHING,
    }),
  },
  mounted() {
    this.setFetching(true);
  },
};
</script>
