<template>
  <div class="fail">
    <Header :withMerchant="false" />
    <section class="main error-section">
      <div class="error-section__message">Кажется, что-то пошло не так :(</div>
      <div class="error-section__status">
        {{ $route.params.status }}
      </div>

      <img
        :src="require('@/assets/img/error/sphere.webp')"
        alt="sphere image"
      />
      <img :src="require('@/assets/img/error/qubiq.webp')" alt="square image" />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/pages/error.scss";
</style>
