<template>
  <section class="field">
    <header class="field__title">Ваша корзина</header>
    <div class="products">
      <div v-for="p in saleInfo" v-bind:key="p.name" class="products__product">
        <div>{{ p.name }}</div>
        <product-button v-bind="p" :onAdd="add" :onRemove="remove" />
      </div>
    </div>
    <div class="total">
      <div>Итого</div>
      <div class="total__amount"><money :value="totalAmount" /></div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ProductButton from "./ProductButton.vue";
import { PRODUCT_ADD, PRODUCT_REMOVE } from "../store/mutations.type";

export default {
  components: { ProductButton },
  computed: {
    ...mapGetters(["saleInfo", "totalAmount"]),
  },
  methods: {
    ...mapMutations({
      add: PRODUCT_ADD,
      remove: PRODUCT_REMOVE,
    }),
  },
};
</script>

<style lang="scss" scoped>
.products {
  margin-top: 21px;
  border-bottom: dashed 1px;
  padding-bottom: 38px;

  &__product {
    &:first-child {
      margin-top: 27px;
    }

    margin-top: 35px;
  }

  &__price {
    font-weight: 600;

    > img {
      position: relative;
      top: 9px;
    }
  }
}

.total {
  margin-top: 38px;
  font-weight: 600;

  &__amount {
    font-size: 43px;
  }
}
</style>
