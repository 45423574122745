import Vue from "vue";
import VueRouter from "vue-router";

import Pay from "../views/Pay.vue";
import Pay2 from "../views/Pay2.vue";
import Pay3 from "../views/Pay3.vue";
import Pay4 from "../views/Pay4.vue";
import Pay5 from "../views/Pay5.vue";
import Pay6 from "../views/Pay6.vue";
import Success from "../views/Success.vue";
import Fail from "../views/Fail.vue";
import Error from "../views/Error.vue";
import Loading from "../views/Loading.vue";
import Fetching from "../views/Fetching.vue";
import Home from "../views/Home.vue";
import Menu from "../views/Menu";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pay",
    name: "Pay",
    component: Pay,
  },
  {
    path: "/pay2",
    name: "Pay2",
    component: Pay2,
  },
  {
    path: "/pay3",
    name: "Pay3",
    component: Pay3,
  },
  {
    path: "/pay/fixed-products/:invoiceId",
    name: "Pay4",
    component: Pay4,
  },
  {
    path: "/pay5",
    name: "Pay5",
    component: Pay5,
  },
  {
    path: "/pay6",
    name: "Pay6",
    component: Pay6,
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
  },
  {
    path: "/fetching",
    name: "Fetching",
    component: Fetching,
  },
  {
    path: "/fail",
    name: "Fail",
    component: Fail,
  },
  {
    path: "/error/:status",
    name: "Error",
    component: Error,
  },
  {
    path: "/loading",
    name: "Loading",
    component: Loading,
  },
  {
    path: "/menu/checkout",
    name: "Checkout",
    component: () => import("@/views/Checkout"),
  },
  {
    path: "/menu/items/checkout",
    name: "CheckoutItems",
    component: () => import("@/views/CheckoutItemsTemplate"),
  },
  {
    path: "/checkout-success",
    name: "Checkout Success",
    component: () =>
      import("@/components/checkout/checkout-result/CheckoutResult"),
  },
  {
    path: "/checkout-error",
    name: "Checkout Error",
    component: () =>
      import("@/components/checkout/checkout-result/CheckoutError"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/custom-amount",
    name: "Custom Amount",
    component: () => import("@/views/CustomAmount"),
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("@/views/Order"),
  },
  {
    path: "/fix",
    name: "FixTemplateRedirectPage",
    component: () => import("@/views/Fix/FixTemplateRedirectPage"),
  },
  {
    path: "/checkout/fix/:invoiceNumber",
    name: "FixSumTemplate",
    component: () => import("@/views/Fix/FixSumTemplate"),
  },
  {
    path: "/tips",
    name: "Tips",
    component: () => import("@/views/Tips"),
  },
  {
    path: "/donation",
    name: "Donation",
    component: () => import("@/views/Tips"),
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: () => import("@/views/Tickets"),
  },
  {
    path: "/checkout-not-available",
    name: "Checkout Not Available",
    component: () => import("@/components/tickets/NotAvailable"),
  },
  {
    path: "/*",
    component: NotFound,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
