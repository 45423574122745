<template>
  <section class="fixed-amount field">
    <header class="field__title">Сумма к оплате</header>
    <div class="fixed-amount__amount"><money :value="amount" /></div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { SET_VALUE } from "../store/mutations.type";

export default {
  name: "CustomAmount",
  computed: {
    ...mapGetters(["amount"]),
  },
  methods: {
    ...mapMutations({
      setValue: SET_VALUE,
    }),
  },
  mounted() {
    this.setValue({ key: "amount", value: "100.00" });
  },
};
</script>

<style lang="scss">
.fixed-amount {
  &__amount {
    font-size: 43px;
    line-height: 52px;
    margin-top: 21px;
  }
}
</style>
