<template>
  <section class="field receipt-send">
    <div class="field__title">Куда вам отправить чек?</div>
    <div class="send-type">
      <div class="send-type-item">
        <input
          id="phone"
          type="radio"
          name="receipt_send_type"
          value="phone"
          :checked="sendType === 'phone'"
          @click="onSendTypeChange"
        />
        <label for="phone">Телефон</label>
      </div>
      <div class="send-type-item">
        <input
          id="email"
          type="radio"
          name="receipt_send_type"
          value="email"
          :checked="sendType === 'email'"
          @click="onSendTypeChange"
        />
        <label for="email">Почта</label>
      </div>
    </div>

    <div v-show="sendType == 'phone'">
      <masked-input
        ref="phone"
        type="text"
        class="field__input_text field__input_tel"
        :mask="phoneMask"
        :guide="false"
        placeholder="+7 (000) 000-00-00"
        placeholderChar="_"
        :value="phone"
        :pipe="phonePipe"
        @keyup="onPhoneChange"
      >
      </masked-input>
    </div>
    <div v-show="sendType == 'email'">
      <input
        type="text"
        class="field__input_text field__input_email"
        :class="{ ['field__input_text_invalid']: validation['email'] !== '' }"
        placeholder="Введите e-mail"
        :value="email"
        @input="onEmailChange"
      />
      <div v-if="validation['email'] !== ''" class="field__validation-message">
        {{ validation["email"] }}
      </div>
    </div>
  </section>
</template>

<script>
// https://github.com/text-mask/text-mask
// https://github.com/text-mask/text-mask/tree/master/vue#readme
import MaskedInput from "vue-text-mask";

import { mapMutations, mapGetters } from "vuex";
import { SET_VALUE } from "../store/mutations.type";
import { masks } from "@/constants";

export default {
  components: { MaskedInput },
  computed: {
    ...mapGetters(["phone", "email", "sendType", "validation"]),
    phoneMask: () => masks.phone.ru,
  },
  name: "ReceiptSend",
  methods: {
    onPhoneChange() {
      const value = this.$refs.phone.$el.value;
      this.setValue({ key: "phone", value });
    },
    onEmailChange(e) {
      this.setValue({ key: "email", value: e.target.value });
    },
    phonePipe(conformedValue) {
      if (conformedValue === "+7 (8") {
        return "+7 (";
      }
      return conformedValue;
    },
    onSendTypeChange(e) {
      this.setValue({ key: "sendType", value: e.target.value });
    },
    ...mapMutations({
      setValue: SET_VALUE,
    }),
  },
};
</script>

<style lang="scss" scoped>
.receipt-send {
  .field__input_text {
    font-size: 21px;
    // line-height: 25px;
    text-align: left;
    padding: 0 16px;
  }

  .send-type {
    font-weight: normal;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 21px;

    .send-type-item {
      input[type="radio"] {
        display: none;

        &:checked + label {
          border-bottom: solid 2px;
        }
      }

      margin-right: 21px;
      line-height: 23px;
    }
  }
}
</style>
