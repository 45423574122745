<template>
  <div class="custom-amount-form">
    <Header />
    <section class="main">
      <Products />
      <ReceiptSend />
      <PayMethods />
      <Footer />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Products from "@/components/Products.vue";
import ReceiptSend from "@/components/ReceiptSend.vue";
import PayMethods from "@/components/PayMethodsWithButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Products,
    ReceiptSend,
    PayMethods,
    Footer,
  },
};
</script>
