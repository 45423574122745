<template>
  <section>
    <Tags :tags="tags" :scrollTo="scrollTo" />
    <section class="menu-items">
      <Items v-for="b in blocks" :items="b.items" :key="b.tag" :class="b.id" />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Tags from "./Tags.vue";
import Items from "./Items.vue";
import { SET_TAG } from "@/store/mutations.type";

export default {
  data: () => ({
    activeTag: "",
    tagIdx: 0,
    initialTop: undefined,
    needProcessScroll: true,
    tagInterval: null,
  }),
  components: {
    Tags,
    Items,
  },
  computed: {
    ...mapGetters(["menuBlock", "itemsBlock"]),
    blocks() {
      if (this.menuBlock) {
        const allItems =
          this.menuBlock?.body?.find((b) => b.name === "items").value || [];
        return allItems.reduce((blocks, i, index) => {
          const tags = i.item_tags;
          tags.forEach((t) => {
            let b = blocks.find((b) => b.tag === t);
            // проверим наличие блока по каждому тэгу
            if (!b) {
              b = {
                id: `tag_${index}`,
                tag: t,
                items: [],
              };
              blocks.push(b);
            }
            b.items.push(i);
          });
          return blocks;
        }, []);
      } else {
        const allItems =
          this.itemsBlock?.body?.find((b) => b.name === "items").value || [];
        return allItems.reduce((blocks, i, index) => {
          const tags = i.item_tags;
          tags.forEach((t) => {
            let b = blocks.find((b) => b.tag === t);
            // проверим наличие блока по каждому тэгу
            if (!b) {
              b = {
                id: `tag_${index}`,
                tag: t,
                items: [],
              };
              blocks.push(b);
            }
            b.items.push(i);
          });
          return blocks;
        }, []);
      }
    },
    tags() {
      if (this.menuBlock) {
        const allItems =
          this.menuBlock?.body?.find((b) => b.name === "items").value || [];
        return allItems.reduce((tags, i) => {
          const itemTags = i.item_tags;
          itemTags.forEach((t) => {
            if (!tags.includes(t)) {
              tags.push(t);
            }
          });
          return tags;
        }, []);
      } else {
        const allItems =
          this.itemsBlock?.body?.find((b) => b.name === "items").value || [];
        return allItems.reduce((tags, i) => {
          const itemTags = i.item_tags;
          itemTags.forEach((t) => {
            if (!tags.includes(t)) {
              tags.push(t);
            }
          });
          return tags;
        }, []);
      }
    },
  },
  methods: {
    ...mapMutations({
      setTag: SET_TAG,
    }),
    scrollTo(tag) {
      const idx = this.tags.indexOf(tag);
      const block = document.querySelectorAll(".menu-items-block")[idx];
      this.needProcessScroll = false;
      const self = this;
      let scrollTimeout;
      function tmpOnScroll() {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
          window.removeEventListener("scroll", tmpOnScroll);
          self.needProcessScroll = true;
        }, 100);
      }
      window.addEventListener("scroll", tmpOnScroll);

      block.scrollIntoView({
        behavior: "smooth",
      });
    },
    onScroll() {
      if (!this.needProcessScroll) {
        return;
      }
      const blocks = Array.from(document.querySelectorAll(".menu-items-block"));
      const activeItem = blocks.find(
        (b) =>
          b.getBoundingClientRect().y +
            b.getBoundingClientRect().height -
            window.innerHeight / 2 >
          0
      );
      const tag = activeItem.classList[activeItem.classList.length - 1];
      const active = this.blocks.find((b) => b.id === tag).tag;

      if (active !== this.activeTag) {
        this.activeTag = active;
        this.setTag(active);
      }
    },
  },
  mounted() {
    const blocks = document.querySelectorAll(".menu-items-block");
    if (blocks.length === 0) {
      return;
    }
    this.initialTop = document.querySelector(".menu-tags").offsetTop;
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/menu.scss";
</style>
