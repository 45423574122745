<template>
  <div class="product-button" ref="btn">
    <div class="product-button__wrap">
      <template v-if="quantity === 1">
        <button @click="addItem" class="product-button-single">
          {{ formatNumber(cose) }} <img src="~@/assets/img/plus.svg" />
        </button>
      </template>
      <template v-if="quantity > 1">
        <button class="product-button-mult__minus" @click="removeItem"></button>
        <div class="product-button-mult__amount">
          {{ formatNumber(cost) }} <img src="~@/assets/img/mult_white.svg" />
          {{ quantity }}
        </div>
        <button class="product-button-mult__plus" @click="addItem"></button>
      </template>
    </div>

    <div
      ref="tmpPrev"
      class="product-button__wrap product-button__wrap_tmp-prev"
    >
      <template v-if="quantity === 2">
        <button @click="addItem" class="product-button-single">
          {{ formatNumber(cost) }} <img src="~@/assets/img/plus.svg" />
        </button>
      </template>
      <template v-if="quantity > 2">
        <button class="product-button-mult__minus" @click="removeItem"></button>
        <div class="product-button-mult__amount">
          {{ formatNumber(cost) }} <img src="~@/assets/img/mult_white.svg" />
          {{ quantity - 1 }}
        </div>
        <button class="product-button-mult__plus" @click="addItem"></button>
      </template>
    </div>

    <div
      ref="tmpNext"
      class="product-button__wrap product-button__wrap_tmp-next"
    >
      <button class="product-button-mult__minus" @click="removeItem"></button>
      <div class="product-button-mult__amount">
        {{ formatNumber(cost) }} <img src="~@/assets/img/mult_white.svg" />
        {{ quantity + 1 }}
      </div>
      <button class="product-button-mult__plus" @click="addItem"></button>
    </div>
  </div>
</template>

<script>
const formatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
});

export default {
  data: function () {
    return {};
  },
  props: ["productName", "cost", "quantity", "onAdd", "onRemove"],
  methods: {
    formatNumber(value) {
      return formatter.format(value);
    },
    collapseButton() {
      const { btn, tmpPrev } = this.$refs;
      const curWidth = btn.scrollWidth;
      const width = tmpPrev.scrollWidth;
      btn.style.width = `${curWidth}px`;
      requestAnimationFrame(() => {
        btn.style.width = `${width}px`;
        btn.addEventListener("transitionend", () => {
          btn.removeEventListener("transitionend", arguments.currency);
          btn.style.width = null;
        });
      });
    },
    expandButton() {
      const { btn, tmpNext } = this.$refs;
      const curWidth = btn.scrollWidth;
      const width = tmpNext.scrollWidth;
      btn.style.width = `${curWidth}px`;
      requestAnimationFrame(() => {
        btn.style.width = `${width}px`;
        btn.addEventListener("transitionend", () => {
          btn.removeEventListener("transitionend", arguments.currency);
          btn.style.width = null;
        });
      });
    },
    addItem() {
      this.expandButton();
      this.onAdd(this.productName);
    },
    removeItem() {
      this.collapseButton();
      this.onRemove(this.productName);
    },
  },
};
</script>

<style lang="scss">
.product-button {
  display: inline-block;
  overflow: hidden;
  transition: width 0.3s ease-out;
  width: auto;
  max-width: 100%;
  color: #fff;

  &__wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    height: 78px;
    border-radius: 9px;
    font-size: 21px;
    background-color: #212125;

    &_tmp-next,
    &_tmp-prev {
      position: absolute;
      visibility: hidden;
      z-index: 0;
    }
  }

  &-single {
    height: 100%;
    padding: 0 20px;
    color: #fff;
    background-color: transparent;
    font-size: 21px;
    border: none;
    border-radius: inherit;
    outline: none;

    > img {
      vertical-align: top;
      margin-left: 12px;
      position: relative;
      top: 2px;
    }
  }

  &-mult {
    color: #fff;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &__minus,
    &__plus {
      width: 60px;
      border: none;
      outline: none;
      height: 90%;
      background-color: #212125;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__minus {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      background-image: url(~@/assets/img/minus.svg);
    }

    &__plus {
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      background-image: url(~@/assets/img/plus.svg);
    }

    &__amount {
      height: 47px;
      padding: 0 32px;
      border-left: solid 1px rgba($color: #fff, $alpha: 0.3);
      border-right: solid 1px rgba($color: #fff, $alpha: 0.3);
      display: flex;
      align-items: center;
    }
  }
}
</style>
