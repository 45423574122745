<template>
  <section v-if="total" class="pay-button-wrap">
    <div class="pay-button-wrap__inner">
      <button v-if="isVisible" @click="nextPage" class="pay-button">
        <div class="pay-button__text">
          <p>{{ buttonName }}</p>
        </div>
        <div class="pay-button__amount">
          <money :value="total" currency="RUB" />
        </div>
      </button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { REQUEST_ORDER } from "@/store/actions.type";
import { getBlock } from "@/store/modules/menu";
import { Block } from "@/constants";
import cartBlockChange from "@/mixins/cartBlockChange";

export default {
  mixins: [cartBlockChange],
  props: ["onClick", "amount", "currency", "isVisible", "buttonName"],
  computed: {
    ...mapGetters(["total", "pageTemplate", "pagePath", "route"]),
  },
  watch: {
    total(val) {
      this.updateCardBlock(val);
    },
  },
  methods: {
    ...mapActions({
      requestOrder: `pay/${REQUEST_ORDER}`,
    }),
    localeProducts() {
      const products = [];
      for (const key in localStorage) {
        if (key.split("_")[0] === this.pagePath) {
          products.push(JSON.parse(localStorage[key]));
        }
      }
      return products.map((product) => ({
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: product.count,
      }));
    },
    updateCardBlock(val) {
      if (this.cardBlock) {
        this.cardBlock.body.find((item) => item.name === "items").value =
          this.localeProducts();
        this.cardBlock.body.find((item) => item.name === "total").value = val;
      }
    },
    nextPage() {
      switch (this.$route.path) {
        case "/order":
          this.getInvoice();
          break;
        default:
          if (
            getBlock(
              this.pageTemplate,
              this.route.main,
              Block.AvailableItemsBlock
            ).name == "AvailableItemsBlock"
          ) {
            return this.$router.push({ name: "CheckoutItems" });
          }
          this.$router.push("/menu/checkout");
          break;
      }
    },
    async getInvoice() {
      getBlock(this.pageTemplate, this.route.main, Block.CartBlock).body =
        this.cardBlock.body;
      try {
        const templateId = JSON.parse(localStorage.getItem("templateId"));
        await this.requestOrder({
          cardBlock: {
            ...this.pageTemplate,
            template_id: templateId,
          },
          payMethod: "order",
        });

        await this.$router.push("/checkout-success");
      } catch (e) {
        await this.$router.push("/checkout-error");
      }
    },
  },
  mounted() {
    this.updateCardBlock(this.total);
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/components/pay-button.scss";
</style>
