<template>
  <section>
    <section class="amount-selector field">
      <header class="field__title">Выберите сумму</header>
      <div class="amount-selector__buttons">
        <div class="amount-selector__button-wrap" v-for="a in amounts" :key="a">
          <button
            class="amount-selector__button"
            :class="
              a === selectedAmount ? 'amount-selector__button_selected' : ''
            "
            @click="select(a)"
          >
            <money :value="a" :fracDigits="false" />
          </button>
        </div>
        <div class="amount-selector__button-wrap">
          <button
            class="amount-selector__button amount-selector__button_other"
            :class="
              'CUSTOM' === selectedAmount
                ? 'amount-selector__button_selected'
                : ''
            "
            @click="select('CUSTOM')"
          >
            Другая сумма
          </button>
        </div>
      </div>
    </section>
    <CustomAmount ref="custom" v-if="selectedAmount === 'CUSTOM'" />
  </section>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { SET_VALUE } from "../store/mutations.type";
import CustomAmount from "./CustomAmount.vue";

export default {
  components: { CustomAmount },
  computed: {
    ...mapGetters(["amounts", "selectedAmount"]),
  },
  methods: {
    select: function (value) {
      this.setValue({ key: "selectedAmount", value });
    },
    ...mapMutations({
      setValue: SET_VALUE,
    }),
  },
  updated: function () {
    if (this.selectedAmount === "CUSTOM") {
      this.$smoothScroll({
        scrollTo: document.querySelector(".custom-amount"),
      });
      const inp = document.querySelector(".custom-amount__input");
      inp.focus();
      inp.select();
    }
  },
};
</script>

<style lang="scss">
.amount-selector {
  &__buttons {
    display: flex;
    flex-flow: row wrap;
  }
  &__button-wrap {
    flex: 0 1 50%;
    margin-top: 18px;
    box-sizing: border-box;

    &:nth-child(odd) {
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
    }
  }

  &__button {
    height: 78px;
    width: 100%;
    font-size: 21px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    border-radius: 18px;

    &_selected {
      border: 2px solid #212125;
    }

    &_other {
      padding: 0 25px;
      line-height: 25px;
      white-space: normal;
    }
  }
}
</style>
