import { SET_ROUTE } from "@/store/mutations.type";
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      setRoute: SET_ROUTE,
    }),
  },
  beforeMount() {
    this.setRoute(this.$route.path);
  },
};
