import { mapGetters } from "vuex";

export default {
  data: () => ({
    cardBlock: null,
  }),
  computed: {
    ...mapGetters(["paymentBlock"]),
  },
  mounted() {
    this.cardBlock = this.paymentBlock || null;
  },
};
