<template>
  <div class="success">
    <Header :withMerchant="false" />
    <section class="main">
      <div class="success__message">
        Ваша оплата прошла успешно! <a href="#">Вот ссылка на чек</a>
      </div>
      <Products :showTitle="false" />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Products from "@/components/Products.vue";

export default {
  components: {
    Header,
    Products,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables";

.success {
  &__message {
    font-size: 43px;
    font-weight: 300;

    a {
      color: $color;
    }
  }
}
</style>
